<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-dialog modal-dialog-centered modal-lg w-100" role="document">
      <div class="modal-content radius-8">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="modal-title" id="sizeGuideModalLabel">Limit Exceeded: Please Contact Your RMM</h5>
          <button type="button" class="close" @click="closeModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0 my-3">
          <div class="text-center">
          <p class="alert-text m-0">You have exceeded your limit. Please contact your RMM to increase your limit or re-enter your quantities.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        required: true
      },
    },
    methods: {
      closeModal() {
        this.$emit('close');
      }
    }
  };
</script>
