<template>
    <div v-if="visible" class="modal-overlay">
      <div class="modal-dialog modal-dialog-centered modal-lg w-100" role="document">
        <div class="modal-content radius-8">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="modal-title" id="sizeGuideModalLabel">Inventory Limit Exceeded</h5>
            <button type="button" class="close" @click="closeModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 my-3">
            <div class="text-center">
              <p class="alert-text m-0">You have exceeded the amount in inventory. The quantity has been updated to the available Quantity. <a href="#">byip@timhortons.com</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
    },
    methods: {
      closeModal() {
        this.$emit('close');
      }
    }
  };
  </script>
  