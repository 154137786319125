<template>
  <div class="card radius-20 tim-card">
    <h3 class="order-title">{{ customer.translations.checkout }}</h3>
    <div class="row field-r-8">
      <div class="col-12">
        <div class="form-group">
          <div class="controls">
            <label for="address">{{ customer.translations.address }}</label>
            <input type="text" name="address" class="form-control" v-model="address" required placeholder="Street 2, 145 California">
          </div>
        </div>
      </div>
    </div>
    <div class="row field-r-8">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="controls">
            <label for="apartment">{{ customer.translations.apartment }}</label>
            <input type="text" name="apartment" class="form-control" v-model="apartment" required placeholder="145">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="controls">
            <label for="city">{{ customer.translations.city }}</label>
            <input type="text" name="city" class="form-control" v-model="city" required placeholder="California">
          </div>
        </div>
      </div>
    </div>
    <div class="row field-r-8">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="controls">
            <label for="province">{{ customer.translations.province }}</label>
            <input type="text" name="province" class="form-control" v-model="province" required placeholder="British Columba">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="controls">
            <label for="country">{{ customer.translations.country }}</label>
            <input type="text" name="country" class="form-control" v-model="country" required placeholder="California">
          </div>
        </div>
      </div>
    </div>
    <div class="row field-r-8">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="controls">
            <label for="postalCode">{{ customer.translations.postal_code }}</label>
            <input type="text" name="postalCode" class="form-control" v-model="postalCode" required placeholder="12345">
          </div>
        </div>
      </div>
    </div>
    <div class="row field-r-8">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="controls">
            <label for="Email">{{ customer.translations.email }}</label>
            <input type="text" name="Email" class="form-control" :value="customer.email" disabled placeholder="test@gmail.com">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="controls">
            <label for="phone">{{ customer.translations.phone }}</label>
            <input type="text" name="phone" class="form-control" v-model="phone" required placeholder="123 456 789">
          </div>
        </div>
      </div>
    </div>
    <div class="row field-r-8">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="controls">
            <label for="orderDate">{{ customer.translations.order_date }}</label>
            <input type="date" name="orderDate" class="form-control" v-model="orderDate" required>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="controls">
            <label for="requiredDate">{{ customer.translations.required_date }}</label>
            <input type="date" name="requiredDate" class="form-control" v-model="requiredDate" required>
          </div>
        </div>
      </div>
    </div>
    <div class="row field-r-8">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="controls">
            <label for="seasonStart">{{ customer.translations.season_start }}</label>
            <input type="date" name="seasonStart" class="form-control" v-model="seasonStart" required>
          </div>
        </div>
      </div>
    </div>

    <h3 class="order-title">{{ customer.translations.order_summary }}</h3>

    <!-- jersey items table -->
    <template v-for="ageGroup in customer.age_groups.flat()">
      <div v-if="isTotalAgeGroupQuantityPresent(ageGroup)" class="table-responsive mb-3">
        <p class="uppercase">{{ customer.translations.jerseys_item }}: {{ ageGroup }}</p>
        <table class="table light-bg m-0 order-table radius-8 summary-table">
          <thead>
            <tr>
              <th>Color</th>
              <th v-for="variant in variants" :key="variant.id">{{ variant.size }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="jersey in jerseys">
              <tr v-if="hasJerseyQuantityInAgeGroup(ageGroup, jersey.id)">
                <td>
                  <div class="d-flex flex-column gap-1 align-items-center">
                    <img :src="jersey.image_url" class="product-img" width="74" alt="Jersey Image" />
                    <p style="color: #4F2D2CE5; font-size: 11px;">
                      {{ customer.soccer_sports ? jersey.color : jersey.title }}
                    </p>
                  </div>  
                </td>
                <td v-for="variant in variants">
                  {{ jerseyVariantQuantities[ageGroup][jersey.id].variants[variant.id] }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </template>

    <div class="d-flex justify-content-end">
      <button class="btn primary-btn w-max" @click="placeOrder">{{ customer.translations.checkout }}</button>
    </div>
  </div>

  <OrderPlacedModal
    :visible="showOrderPlacedModal"
    :order="order"
    :customer="customer"
    @close="showOrderPlacedModal = false"
  />
</template>

<script>
import OrderPlacedModal from './OrderPlacedModal.vue';

export default {
  data() {
    return {
      address: '',
      apartment: '',
      city: '',
      province: '',
      country: '',
      postalCode: '',
      email: '',
      phone: '',
      orderDate: '',
      requiredDate: '',
      seasonStart: '',
      showOrderPlacedModal: false,
      order: null,
    };
  },
  components: {
    OrderPlacedModal,
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    jerseyVariantQuantities: {
      type: Object,
      required: true
    },
    shortsVariantQuantities: {
      type: Object,
      required: true
    },
    socksVariantQuantities: {
      type: Object,
      required: true
    },
    ballsVariantQuantities: {
      type: Object,
      required: true
    },
    orderImage: File,
    jerseys: Array,
    variants: Array,
    hasJerseyQuantityInAgeGroup: Function,
    isTotalAgeGroupQuantityPresent: Function,
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    openConfirmAddressModal() {
      this.$emit('openConfirmAddressModal')
      this.$emit('close');
    },
    placeOrder() {
      if (!this.address || !this.city || !this.province || !this.country || !this.postalCode || !this.phone || !this.orderDate || !this.requiredDate || !this.seasonStart) {
        alert("Please fill in all required fields.");
        return;
      }

      const lineItems = this.constructLineItems();

      // Use FormData to include both JSON data and the image
      const formData = new FormData();

      // Append all the order data
      formData.append('order[order_date]', this.orderDate);
      formData.append('order[season_start_date]', this.seasonStart);
      formData.append('order[phone]', this.phone);
      formData.append('order[sports_type]', 'soccer'); // Replace with dynamic value if needed
      formData.append('order[postal_code]', this.postalCode);
      formData.append('order[province]', this.province);
      formData.append('order[country]', this.country);
      formData.append('order[city]', this.city);
      formData.append('order[address]', this.address);

      // Append line items as JSON string
      formData.append('order[line_items_attributes]', JSON.stringify(lineItems));

      // Append the image if present
      if (this.orderImage) {
        formData.append('order[image]', this.orderImage);
      }

      // Make the fetch request with FormData
      fetch('/orders.js', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: formData // Send formData instead of JSON
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Order placed successfully:', data);
        this.order = data.order;
        this.showOrderPlacedModal = true;
        // window.location.reload();
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
    },
    constructLineItems() {
      const lineItems = [];

      Object.entries(this.jerseyVariantQuantities).forEach(([ageGroup, productQuantities]) => {
        Object.entries(productQuantities).forEach(([productId, productData]) => {
          const productSize = productData.size;
          Object.entries(productData.variants).forEach(([variantId, quantity]) => {
            if (quantity > 0) {
              lineItems.push({
                variant_id: variantId,
                quantity: quantity,
                product_type: 'Jerseys',
                product_id: productId,
                size: productSize,
                team_name: ageGroup,
                mylar_logo: '0'
              });
            }
          });
        });
      });

      Object.entries(this.socksVariantQuantities).forEach(([ageGroup, productQuantities]) => {
        Object.entries(productQuantities).forEach(([productId, productData]) => {
          const productSize = productData.size;
          Object.entries(productData.variants).forEach(([variantId, quantity]) => {
            if (quantity > 0) {
              lineItems.push({
                variant_id: variantId,
                quantity: quantity,
                product_type: 'Socks',
                product_id: productId,
                size: productSize,
                team_name: ageGroup,
                mylar_logo: '0'
              });
            }
          });
        });
      });

      Object.entries(this.shortsVariantQuantities).forEach(([ageGroup, productQuantities]) => {
        Object.entries(productQuantities).forEach(([productId, productData]) => {
          const productSize = productData.size;
          Object.entries(productData.variants).forEach(([variantId, quantity]) => {
            if (quantity > 0) {
              lineItems.push({
                variant_id: variantId,
                quantity: quantity,
                product_type: 'Socks',
                product_id: productId,
                size: productSize,
                team_name: ageGroup,
                mylar_logo: '0'
              });
            }
          });
        });
      });

      Object.entries(this.ballsVariantQuantities).forEach(([ageGroup, productQuantities]) => {
        Object.entries(productQuantities).forEach(([productId, productData]) => {
          const productSize = productData.size;
          Object.entries(productData.variants).forEach(([variantId, quantity]) => {
            if (quantity > 0) {
              lineItems.push({
                variant_id: variantId,
                quantity: quantity,
                product_type: 'Socks',
                product_id: productId,
                size: productSize,
                team_name: ageGroup,
                mylar_logo: '0'
              });
            }
          });
        });
      });

      return lineItems;
    }
  }
};
</script>
