<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-dialog" role="document">
      <div class="modal-content radius-8 px-4">
        <div class="text-center">
          <h3 class="modal-title mb-8" id="confirmModalLabel">{{ customer.translations.order_confirmation }}!</h3>
          <p class="modal-text">{{ customer.translations.order_confirmation_text }}</p>
          <div class="d-flex flex-column justify-content-center max-280 mt-2">
            <button class="btn primary-btn px-1" @click="placeOrder">{{ customer.translations.proceed }}</button>
            <button type="button" class="btn modal-secondary-btn no-btn close mt-2" @click="closeModal" aria-label="Close">{{ customer.translations.back_to_order }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ConfirmAddressModal
    v-if="showConfirmAddressModal" 
    :visible="showConfirmAddressModal"  
    :order="order"
    :customer="customer"
  />
</template>

<script>
import ConfirmAddressModal from './ConfirmAddressModal.vue';

export default {
  data() {
    return {
      order: null,
      showConfirmAddressModal: false,
    }
  },
  components: {
    ConfirmAddressModal,
  },
  props: {
    jerseys: Array,
    variants: Array,
    shortsVariants: Array,
    socksVariants: Array,
    ballsVariants: Array,
    medalsVariants: Array,
    visible: {
      type: Boolean,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    jerseyVariantQuantities: {
      type: Object,
      required: true
    },
    shortsVariantQuantities: {
      type: Object,
      required: true
    },
    socksVariantQuantities: {
      type: Object,
      required: true
    },
    ballsVariantQuantities: {
      type: Object,
      required: true
    },
    medalsVariantQuantities: {
      type: Object,
      required: true,
    },
    orderImage: File,
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    openConfirmAddressModal() {
      this.$emit('openConfirmAddressModal')
      this.$emit('close');
    },
    placeOrder() {
      const lineItems = this.constructLineItems();

      const formData = new FormData();

      formData.append('order[line_items_attributes]', JSON.stringify(lineItems));

      if (this.orderImage) {
        formData.append('order[image]', this.orderImage);
      }

      formData.append('order[sports_type]', 'soccer'); // TODO: add dynamic sports type here.

      fetch('/orders.js', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: formData // Send formData instead of JSON
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Order placed successfully:', data);
        this.order = data.order;
        this.showConfirmAddressModal = true;
        this.$emit('close');
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
    },

    constructLineItems() {
      const lineItems = [];
      const context = this;

      Object.entries(this.jerseyVariantQuantities).forEach(([ageGroup, productQuantities]) => {
        Object.entries(productQuantities).forEach(([productId, productData]) => {
          Object.entries(productData.variants).forEach(([variantId, quantity]) => {
            if (quantity > 0) {
              const jersey = context.jerseys.find(j => j.id == productId);
              const variantData = jersey.variants.find(v => v.id == variantId);
              const productSize = variantData ? variantData.size : null;

              lineItems.push({
                variant_id: variantId,
                quantity: quantity,
                product_type: 'Jerseys',
                product_id: productId,
                size: productSize,
                team_name: ageGroup,
                mylar_logo: '0'
              });
            }
          });
        });
      });

      Object.entries(this.socksVariantQuantities).forEach(([productId, productData]) => {
        Object.entries(productData.variants).forEach(([variantId, quantity]) => {
          const variantData = context.socksVariants.find(v => v.id == variantId);
          const productSize = variantData ? variantData.size : null;

          if (quantity > 0) {
            lineItems.push({
              variant_id: variantId,
              quantity: quantity,
              product_type: 'Socks',
              product_id: productId,
              size: productSize,
              mylar_logo: '0'
            });
          }
        });
      });

      Object.entries(this.shortsVariantQuantities).forEach(([productId, productData]) => {
        Object.entries(productData.variants).forEach(([variantId, quantity]) => {
          const variantData = context.shortsVariants.find(v => v.id == variantId);
          const productSize = variantData ? variantData.size : null;

          if (quantity > 0) {
            lineItems.push({
              variant_id: variantId,
              quantity: quantity,
              product_type: 'Shorts',
              product_id: productId,
              size: productSize,
              mylar_logo: '0'
            });
          }
        });
      });

      Object.entries(this.ballsVariantQuantities).forEach(([productId, productData]) => {
        Object.entries(productData.variants).forEach(([variantId, quantity]) => {
          const variantData = context.ballsVariants.find(v => v.id == variantId);
          const productSize = variantData ? variantData.size : null;

          if (quantity > 0) {
            lineItems.push({
              variant_id: variantId,
              quantity: quantity,
              product_type: 'Balls',
              product_id: productId,
              size: productSize,
              mylar_logo: '0'
            });
          }
        });
      });

      Object.entries(this.medalsVariantQuantities).forEach(([productId, productData]) => {
        Object.entries(productData.variants).forEach(([variantId, quantity]) => {
          if(variantId == 'addMylarLogo') {
            return;
          }

          const variantData = context.medalsVariants.find(v => v.id == variantId);
          const productSize = variantData ? variantData.size : null;

          if (quantity > 0) {
            lineItems.push({
              variant_id: variantId,
              quantity: quantity,
              product_type: 'Medals',
              product_id: productId,
              size: productSize,
              mylar_logo: productData.variants['addMylarLogo'] ? '1' : '0'
            });
          }
        });
      });

      return lineItems;
    }
  }
};
</script>
