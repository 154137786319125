<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-dialog modal-dialog-centered modal-lg w-100" role="document">
      <div class="modal-content radius-8">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="modal-title" id="sizeGuideModalLabel">{{ guideTitle }}</h5>
          <button type="button" class="close" @click="closeModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0 my-3">
          <div class="text-center">
            <img :src="imageUrl" alt="Size Guide" v-if="imageUrl" />
            <p v-else class="m-0">{{ noImageMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    vendor: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    guideTitle() {
      return this.type === 'jerseys' ? this.customer.translations.jersey_size_guide : this.customer.translations.shorts_size_guide;
    },
    imageUrl() {
      if (this.type === 'jerseys') {
        if (this.customer.soccer_sports) {
          if (this.customer.language === 'ENGLISH') {
            return this.vendor?.size_guide_image_url || null;
          } else if (this.customer.language === 'FRENCH') {
            return this.vendor?.french_soccer_jersey_size_guide_url || null;
          }
        } else if (this.customer.hockey_sports) {
          if (this.customer.language === 'ENGLISH') {
            return this.vendor?.hockey_size_guide_image_url || null;
          } else if (this.customer.language === 'FRENCH') {
            return this.vendor?.french_hockey_jersey_size_guide_url || null;
          }
        }
      } else if (this.type === 'shorts') {
        return this.vendor?.shorts_size_guide_image_url || null;
      }
      return null;
    },
    noImageMessage() {
      return 'No Size Guide Available';
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>
