<template>
  <div v-if="limitExceeded" class="d-flex align-items-center justify-content-between alert-tim fade-in">
    <div class="d-flex flex-column gap-1">
      <h3 class="d-flex alert-heading m-0 gap-1">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_2878_12905" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 13C11.45 13 11 12.55 11 12V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V12C13 12.55 12.55 13 12 13ZM11 15V17H13V15H11Z" fill="black"/>
          </mask>
          <g mask="url(#mask0_2878_12905)">
          <rect width="24" height="24" fill="#B95000"/>
          </g>
        </svg>
        Inventory Limit Exceeded
      </h3>
      <p class="alert-text m-0">You have exceeded the amount in inventory. The quantity has been updated to the available Quantity. <a href="#">byip@timhortons.com</a></p>
    </div>
    <div>
      <button @click="closeAlert" class="no-btn fade-out">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_2878_12902" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="5" y="5" width="14" height="14">
          <path d="M18.2997 5.70996C18.1129 5.5227 17.8592 5.41747 17.5947 5.41747C17.3302 5.41747 17.0766 5.5227 16.8897 5.70996L11.9997 10.59L7.10973 5.69996C6.9229 5.5127 6.66924 5.40747 6.40473 5.40747C6.14021 5.40747 5.88656 5.5127 5.69973 5.69996C5.30973 6.08996 5.30973 6.71996 5.69973 7.10996L10.5897 12L5.69973 16.89C5.30973 17.28 5.30973 17.91 5.69973 18.3C6.08973 18.69 6.71973 18.69 7.10973 18.3L11.9997 13.41L16.8897 18.3C17.2797 18.69 17.9097 18.69 18.2997 18.3C18.6897 17.91 18.6897 17.28 18.2997 16.89L13.4097 12L18.2997 7.10996C18.6797 6.72996 18.6797 6.08996 18.2997 5.70996Z" fill="black"/>
          </mask>
          <g mask="url(#mask0_2878_12902)">
          <rect width="24" height="24" fill="#9098A1"/>
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      limitExceeded: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.closeAlert(); // Auto close after 5 seconds
    }, 5000);
  },
  methods: {
    closeAlert() {
      this.limitExceeded = false; // Will trigger fade-out and hide the alert
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.alert-tim {
  background: #FFF4EC;
  padding: 16px 20px;
  margin-bottom: 15px;
  position: absolute;
  top: 0%;
  right: 43%;
  z-index: 999;
  max-width: 580px;
  opacity: 1;
  transition: opacity 1s ease;
}

.fade-in {
  animation: fadeIn 1s forwards;
}

.fade-out {
  animation: fadeOut 1s forwards;
}

.alert-heading {
  color: #B95000;
  font-size: 20px;
  font-weight: 600;
}

.alert-text {
  color: #7B4C4699;
  font-size: 13px;
  font-weight: 600;
}

.alert-text a {
  font-weight: 800;
  color: #7B4C4699;
}

.alert-text a:hover {
  background: transparent;
  color: #7B4C4699;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
