<template>
  <div>
    <div v-if="currentUser.soccer_sports">
      <div class="card-content card-img">
        <img class="w-100" :src="soccerImage" />
      </div>
    </div>
    <div v-if="currentUser.hockey_sports">
      <div class="card-content card-img">
        <img class="w-100" :src="hockeyImage" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['currentUser'],
  computed: {
    soccerImage() {
      return this.currentUser.language === 'ENGLISH'
        ? require('../../assets/images/english_soccer.jpg')
        : require('../../assets/images/french_soccer.jpg');
    },
    hockeyImage() {
      return this.currentUser.language === 'ENGLISH'
        ? require('../../assets/images/english_hockey.jpg')
        : require('../../assets/images/french_hockey.jpg');
    }
  }
};
</script>