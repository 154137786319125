<template>
  <div class="col-12 d-flex justify-content-between align-items-center mb-2 p-0">
    <div class="d-flex gap-2 align-items-center">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.5867 2.66663H10.4134C5.56002 2.66663 2.66669 5.55996 2.66669 10.4133V21.5733C2.66669 26.44 5.56002 29.3333 10.4134 29.3333H21.5734C26.4267 29.3333 29.32 26.44 29.32 21.5866V10.4133C29.3334 5.55996 26.44 2.66663 21.5867 2.66663ZM24 17H10.4134L14.4267 21.0133C14.8134 21.4 14.8134 22.04 14.4267 22.4266C14.2267 22.6266 13.9734 22.72 13.72 22.72C13.4667 22.72 13.2134 22.6266 13.0134 22.4266L7.29335 16.7066C7.10669 16.52 7.00002 16.2666 7.00002 16C7.00002 15.7333 7.10669 15.48 7.29335 15.2933L13.0134 9.57329C13.4 9.18663 14.04 9.18663 14.4267 9.57329C14.8134 9.95996 14.8134 10.6 14.4267 10.9866L10.4134 15H24C24.5467 15 25 15.4533 25 16C25 16.5466 24.5467 17 24 17Z" fill="#3F2021"/>
      </svg>
      <h3 class="m-0 order-flow-title fs-26">Place Your Order</h3>
    </div>
    <div class="jersey-options w-100" style="max-width: 217px;">
      <select v-model="selectedAgeGroup" :class="{'has-value': selectedAgeGroup}" class="styled-dropdown">
        <option v-for="ageGroup in ageGroups" :key="ageGroup" :value="ageGroup">
          {{ ageGroup }}
        </option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ul class="list-unstyled category-bullet p-0 gap-2 my-32 ff-mont">
        <li v-for="(ageGroup, index) in visibleAgeGroups" :key="index">
          <a
             :class="['bullet-link', { 'active-link': activeIndex >= index }]">
            <div class="bullet-icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 11.9999H21C21.2117 11.9999 21.4139 11.9106 21.556 11.7531C21.698 11.5964 21.7676 11.3862 21.7464 11.1752L21.1993 5.70181C21.0455 4.16304 19.985 2.89374 18.4974 2.46894L15.2059 1.52851C15.025 1.47729 14.8316 1.49484 14.6646 1.57906C12.9968 2.41404 11.0043 2.41404 9.33548 1.57906C9.16771 1.49484 8.97473 1.47721 8.79383 1.52851L5.50231 2.46894C4.01513 2.89374 2.95493 4.16304 2.80118 5.70181L2.25368 11.1752C2.23276 11.3861 2.30161 11.5964 2.44373 11.7531C2.58616 11.9106 2.78798 11.9999 3.00001 11.9999H6.00001V21.7499C6.00001 22.1645 6.33586 22.4999 6.75001 22.4999H17.25C17.6645 22.4999 18 22.1645 18 21.7499V11.9999ZM18 10.4999V8.24994C18 7.83541 17.6645 7.49994 17.25 7.49994C16.8355 7.49994 16.5 7.83541 16.5 8.24994V11.2499V20.9999H7.50001V11.2499V8.24994C7.50001 7.83541 7.16416 7.49994 6.75001 7.49994C6.33586 7.49994 6.00001 7.83541 6.00001 8.24994V10.4999H3.82876L4.29383 5.85129C4.38608 4.92766 5.02223 4.16596 5.91428 3.91179L8.93408 3.04906C10.8754 3.92214 13.1258 3.92214 15.0659 3.04906L18.0857 3.91186C18.9778 4.16671 19.6143 4.92774 19.7066 5.85129L20.1716 10.4999H18ZM14.25 7.49994H9.75001C9.33586 7.49994 9.00001 7.16446 9.00001 6.74994C9.00001 6.33541 9.33586 5.99994 9.75001 5.99994H14.25C14.6645 5.99994 15 6.33541 15 6.74994C15 7.16446 14.6645 7.49994 14.25 7.49994ZM10.9145 16.4208C11.0222 16.4743 11.1368 16.4999 11.2493 16.4999C11.5247 16.4999 11.7894 16.3484 11.9209 16.0854L14.9209 10.0854C15.0374 9.85321 15.0249 9.57706 14.888 9.35589C14.751 9.13471 14.51 8.99994 14.25 8.99994H9.75001C9.33586 8.99994 9.00001 9.33541 9.00001 9.74994C9.00001 10.1645 9.33586 10.4999 9.75001 10.4999H13.0364L10.5791 15.4145C10.3938 15.785 10.544 16.2355 10.9145 16.4208Z" fill="white"/>
              </svg>
            </div>
            <span>{{ ageGroup }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ageGroups: [],
      activeIndex: 0,
      selectedAgeGroup: null,
    };
  },
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    visibleAgeGroups() {
      return this.ageGroups.slice(this.activeIndex, this.activeIndex + 3);
    },
  },
  watch: {
    activeIndex(newIndex) {
      this.selectedAgeGroup = this.ageGroups[newIndex];
    },
    selectedAgeGroup(newAgeGroup) {
      const newIndex = this.ageGroups.indexOf(newAgeGroup);
      if (newIndex >= 0) {
        this.activeIndex = Math.min(newIndex, this.ageGroups.length - 3);
      }
    },
  },
  mounted() {
    this.ageGroups = this.customer.age_groups.flat();
    this.selectedAgeGroup = this.ageGroups[this.activeIndex];
  },
  methods: {
    updateActiveBreadcrumb(index) {
      if (index === 2 && this.activeIndex + 3 < this.ageGroups.length) {
        this.activeIndex++;
      } else if (index === 0 && this.activeIndex > 0) {
        this.activeIndex--;
      } else {
        this.activeIndex = Math.max(0, this.activeIndex + index);
      }
    },
    updateBreadcrumbFromDropdown() {
      const newIndex = this.ageGroups.indexOf(this.selectedAgeGroup);
      this.activeIndex = Math.min(newIndex, this.ageGroups.length - 3);
    },
  },
};
</script>
