<template>
  <div class="card radius-20 summary-order tim-card">
    <div class="card-content">
      <h3 class="order-title">{{ customer.translations.order_summary }}</h3>
      <div class="d-flex flex-wrap row-gap-3">
        <div class="d-flex w-50 align-items-center gap-1">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.10646 7.74658C8.08646 7.74658 8.07313 7.74658 8.05313 7.74658C8.01979 7.73992 7.97313 7.73992 7.93313 7.74658C5.99979 7.68658 4.53979 6.16658 4.53979 4.29325C4.53979 2.38659 6.09313 0.833252 7.99979 0.833252C9.90646 0.833252 11.4598 2.38659 11.4598 4.29325C11.4531 6.16658 9.98646 7.68658 8.12646 7.74658C8.11979 7.74658 8.11313 7.74658 8.10646 7.74658ZM7.99979 1.83325C6.64646 1.83325 5.5398 2.93992 5.5398 4.29325C5.5398 5.62659 6.57979 6.69992 7.90646 6.74658C7.93979 6.73992 8.03313 6.73992 8.1198 6.74658C9.42646 6.68658 10.4531 5.61325 10.4598 4.29325C10.4598 2.93992 9.35313 1.83325 7.99979 1.83325Z" fill="#C8102F"/>
            <path d="M8.11307 15.0334C6.80641 15.0334 5.49307 14.7001 4.49974 14.0334C3.57307 13.4201 3.06641 12.5801 3.06641 11.6667C3.06641 10.7534 3.57307 9.90675 4.49974 9.28675C6.49974 7.96008 9.73974 7.96008 11.7264 9.28675C12.6464 9.90008 13.1597 10.7401 13.1597 11.6534C13.1597 12.5667 12.6531 13.4134 11.7264 14.0334C10.7264 14.7001 9.41974 15.0334 8.11307 15.0334ZM5.05307 10.1267C4.41307 10.5534 4.06641 11.1001 4.06641 11.6734C4.06641 12.2401 4.41974 12.7867 5.05307 13.2067C6.71307 14.3201 9.51307 14.3201 11.1731 13.2067C11.8131 12.7801 12.1597 12.2334 12.1597 11.6601C12.1597 11.0934 11.8064 10.5467 11.1731 10.1267C9.51307 9.02008 6.71307 9.02008 5.05307 10.1267Z" fill="#C8102F"/>
          </svg>
          <div>
            <h5>Associate Name</h5>
            <p>{{ customer.club_name }}</p>
          </div>
        </div>
        <div class="d-flex w-50 align-items-center gap-1">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.10671 7.24658C6.04004 7.23992 5.96004 7.23992 5.88671 7.24658C4.30004 7.19325 3.04004 5.89325 3.04004 4.29325C3.04004 2.65992 4.36004 1.33325 6.00004 1.33325C7.63337 1.33325 8.96004 2.65992 8.96004 4.29325C8.95337 5.89325 7.69337 7.19325 6.10671 7.24658Z" stroke="#C8102F" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.9401 2.66675C12.2334 2.66675 13.2734 3.71341 13.2734 5.00008C13.2734 6.26008 12.2734 7.28675 11.0267 7.33341C10.9734 7.32675 10.9134 7.32675 10.8534 7.33341" stroke="#C8102F" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.77335 9.70675C1.16002 10.7867 1.16002 12.5467 2.77335 13.6201C4.60669 14.8467 7.61335 14.8467 9.44669 13.6201C11.06 12.5401 11.06 10.7801 9.44669 9.70675C7.62002 8.48675 4.61335 8.48675 2.77335 9.70675Z" stroke="#C8102F" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.2267 13.3333C12.7067 13.2333 13.16 13.0399 13.5334 12.7533C14.5734 11.9733 14.5734 10.6866 13.5334 9.90659C13.1667 9.62659 12.72 9.43992 12.2467 9.33325" stroke="#C8102F" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div>
            <h5>Group Name: </h5>
            <p>{{ customer.mfjd_age_group }}</p>
          </div>
        </div>
        <div class="d-flex w-50 align-items-center gap-1">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.80005 15.1666H6.20005C2.90671 15.1666 1.50671 13.76 1.50671 10.4733V7.47998C1.50671 7.20665 1.73338 6.97998 2.00671 6.97998C2.28005 6.97998 2.50671 7.20665 2.50671 7.47998V10.4733C2.50671 13.2 3.47338 14.1666 6.20005 14.1666H9.79338C12.52 14.1666 13.4867 13.2 13.4867 10.4733V7.47998C13.4867 7.20665 13.7134 6.97998 13.9867 6.97998C14.26 6.97998 14.4867 7.20665 14.4867 7.47998V10.4733C14.4934 13.76 13.0867 15.1666 9.80005 15.1666Z" fill="#C8102F"/>
            <path d="M7.99996 8.49992C7.26663 8.49992 6.59996 8.21325 6.12663 7.68658C5.65329 7.15992 5.43329 6.47325 5.50663 5.73992L5.95329 1.28659C5.97996 1.03325 6.19329 0.833252 6.45329 0.833252H9.56663C9.82663 0.833252 10.04 1.02659 10.0666 1.28659L10.5133 5.73992C10.5866 6.47325 10.3666 7.15992 9.89329 7.68658C9.39996 8.21325 8.73329 8.49992 7.99996 8.49992ZM6.89996 1.83325L6.49996 5.83992C6.45329 6.28659 6.58663 6.70659 6.86663 7.01325C7.43329 7.63992 8.56663 7.63992 9.13329 7.01325C9.41329 6.69992 9.54663 6.27992 9.49996 5.83992L9.09996 1.83325H6.89996Z" fill="#C8102F"/>
            <path d="M12.2066 8.49992C10.8533 8.49992 9.64663 7.40659 9.50663 6.05992L9.03996 1.38659C9.02663 1.24659 9.07329 1.10659 9.16663 0.999919C9.25996 0.893252 9.39329 0.833252 9.53996 0.833252H11.5733C13.5333 0.833252 14.4466 1.65325 14.72 3.66659L14.9066 5.51992C14.9866 6.30658 14.7466 7.05325 14.2333 7.61992C13.72 8.18658 13 8.49992 12.2066 8.49992ZM10.0933 1.83325L10.5066 5.95992C10.5933 6.79325 11.3666 7.49992 12.2066 7.49992C12.7133 7.49992 13.1666 7.30659 13.4933 6.95325C13.8133 6.59992 13.96 6.12659 13.9133 5.61992L13.7266 3.78659C13.52 2.27992 13.0333 1.83325 11.5733 1.83325H10.0933Z" fill="#C8102F"/>
            <path d="M3.76001 8.49992C2.96668 8.49992 2.24668 8.18658 1.73335 7.61992C1.22001 7.05325 0.980014 6.30658 1.06001 5.51992L1.24001 3.68659C1.52001 1.65325 2.43335 0.833252 4.39335 0.833252H6.42668C6.56668 0.833252 6.70001 0.893252 6.80001 0.999919C6.90001 1.10659 6.94001 1.24659 6.92668 1.38659L6.46001 6.05992C6.32001 7.40659 5.11335 8.49992 3.76001 8.49992ZM4.39335 1.83325C2.93335 1.83325 2.44668 2.27325 2.23335 3.79992L2.05335 5.61992C2.00001 6.12659 2.15335 6.59992 2.47335 6.95325C2.79335 7.30659 3.24668 7.49992 3.76001 7.49992C4.60001 7.49992 5.38001 6.79325 5.46001 5.95992L5.87335 1.83325H4.39335Z" fill="#C8102F"/>
            <path d="M9.66671 15.1666H6.33337C6.06004 15.1666 5.83337 14.9399 5.83337 14.6666V12.9999C5.83337 11.5999 6.60004 10.8333 8.00004 10.8333C9.40004 10.8333 10.1667 11.5999 10.1667 12.9999V14.6666C10.1667 14.9399 9.94004 15.1666 9.66671 15.1666ZM6.83337 14.1666H9.16671V12.9999C9.16671 12.1599 8.84004 11.8333 8.00004 11.8333C7.16004 11.8333 6.83337 12.1599 6.83337 12.9999V14.1666Z" fill="#C8102F"/>
          </svg>
          <div>
            <h5>Vendor: </h5>
            <p v-if="vendor && vendor.name">{{ vendor.name }}</p>
          </div>
        </div>
      </div>
      <div class="d-flex radius-8 light-bg my-3 w-100 text-center">
        <div class="p-1 w-50" style="border-right: 1px solid #DDC8C433;">
          <h4 class="sum-value">20</h4>
          <h6 class="sum-title">Maximum Quantity</h6>
        </div>
        <div class="x-seprator"></div>
        <div class="p-1 w-50">
          <h4 class="sum-value">0</h4>
          <h6 class="sum-title">Ordered</h6>
        </div>
      </div>
      

      <div class="d-flex mb-1 gap-2">
        <a href="javascript:void(0)" class="btn outlined-primary-btn px-1 w-50">Next Age Group</a>
        <button class="btn primary-btn px-1 w-50" @click="openConfirmOrderModal">
          Complete
        </button>
      </div>
      <button class="text-center btn modal-secondary-btn w-100" @click="openCancelOrderModal">
        Cancel Order
      </button>

      <CancelOrderModal
        :visible="showCancelOrderModal"
        :currentUser="currentUser"
        :customer="customer"
        :vendor="vendor"
        @close="showCancelOrderModal = false"
      />

      <ConfirmOrderModal
        :visible="showConfirmOrderModal"
        :currentUser="currentUser"
        :customer="customer"
        :vendor="vendor"
        @close="showConfirmOrderModal = false"
      />
    </div>
  </div>
</template>

<script>
import CancelOrderModal from './CancelOrderModal.vue';
import ConfirmOrderModal from './ConfirmOrderModal.vue';

export default {
  components: {
    CancelOrderModal,
    ConfirmOrderModal,
  },
  name: 'OrderSummary',
  data() {
    return {
      showCancelOrderModal: false,
      showConfirmOrderModal: false
    }
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    vendor: {
      type: Object,
      required: true
    }
  },
  methods: {
    openCancelOrderModal() {
      this.showCancelOrderModal = true
    },
    openConfirmOrderModal() {
      this.showConfirmOrderModal = true
    }
  }
}
</script>
