import { createApp } from 'vue';
import AppContent from '../components/AppContent.vue';
import PlaceOrder from '../components/PlaceOrder.vue';
import Preloader from '../components/Preloader.vue';
import SoccerHockeyCards from '../components/SoccerAndHockeyCards.vue';
import InformRmm from '../components/InformRmm.vue';
import LimitExceeded from '../components/LimitExceeded.vue';
import InformRmmModal from '../components/InformRmmModal.vue';
import LimitExceededModal from '../components/LimitExceededModal.vue';
import LogoSelection from '../components/LogoSelection.vue';
import LogoUpload from '../components/LogoUpload.vue';
import AgeGroup from '../components/AgeGroup.vue';
import PlaceYourOrder from '../components/PlaceYourOrder.vue';
import SizeGuideModal from '../components/SizeGuideModal.vue';
import OrderSummary from '../components/OrderSummary.vue';
import CancelOrderModal from '../components/CancelOrderModal.vue';
import ConfirmOrderModal from '../components/ConfirmOrderModal.vue';
import ConfirmAddressModal from '../components/ConfirmAddressModal.vue';
import CategoryBullets from '../components/CategoryBullets.vue';
import CheckoutPage from '../components/CheckoutPage.vue';
import OrderPlacedModal from '../components/OrderPlacedModal.vue';

document.addEventListener('turbolinks:load', () => {
  const vueAppElement = document.getElementById('vue-app');
  if (vueAppElement) {
    const app = createApp(AppContent);

    app.component('place-order', PlaceOrder);
    app.component('pre-loader', Preloader);
    app.component('soccer-hockey-cards', SoccerHockeyCards);
    app.component('inform-rmm', InformRmm);
    app.component('limit-exceeded', LimitExceeded);
    app.component('inform-rmm-modal', InformRmmModal);
    app.component('limit-exceeded-modal', LimitExceededModal);
    app.component('logo-selection', LogoSelection);
    app.component('logo-upload', LogoUpload);
    app.component('age-group', AgeGroup);
    app.component('place-your-order', PlaceYourOrder);
    app.component('size-guide-modal', SizeGuideModal);
    app.component('order-summary', OrderSummary);
    app.component('cancel-order-modal', CancelOrderModal);
    app.component('confirm-order-modal', ConfirmOrderModal);
    app.component('confirm-address-modal', ConfirmAddressModal);
    app.component('category-bullets', CategoryBullets);
    app.component('checkout-page', CheckoutPage);
    app.component('order-placed-modal', OrderPlacedModal);

    app.mount(vueAppElement);







  }
});
