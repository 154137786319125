<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-dialog" role="document">
      <div class="modal-content radius-8 px-4">
        <div class="text-center">
          <h3 class="modal-title mb-8" id="cancelModalLabel">Confirmation</h3>
          <p class="modal-text">{{ customer.translations.order_address_confirmation }}</p>
          <div class="d-flex flex-column justify-content-center max-280 my-2">
            <button href="#" class="btn primary-btn px-1" @click="openCheckoutPage">Continue</button>
            <!-- <button type="button" class="btn modal-secondary-btn no-btn close mt-2" @click="closeModal" aria-label="Close">Update</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    openCheckoutPage() {
      window.location.href = `/orders/${this.order.id}/edit`;
      this.$emit('close');
    }
  }
};
</script>

