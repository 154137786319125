<template>
  <div class="col-12">
    <div v-if="showLogoSelection" class="card active-users radius-20 full-h-card mb-0" id="monetaryCustomers" style="padding-top: 110px;">
      <div class="card-header border-0 bg-transparent">
      <div class="main-title row">
        <div class="col-xl-3 col-lg-4 col-md-4"></div>
        <div class="col-xl-6 col-lg-5 col-md-5">
          <div class="row">
            <div class="col-12 d-flex gap-2 align-items-center mb-32">
              <a href="customer_views" class="back-btn">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.5867 2.66663H10.4134C5.56002 2.66663 2.66669 5.55996 2.66669 10.4133V21.5733C2.66669 26.44 5.56002 29.3333 10.4134 29.3333H21.5734C26.4267 29.3333 29.32 26.44 29.32 21.5866V10.4133C29.3334 5.55996 26.44 2.66663 21.5867 2.66663ZM24 17H10.4134L14.4267 21.0133C14.8134 21.4 14.8134 22.04 14.4267 22.4266C14.2267 22.6266 13.9734 22.72 13.72 22.72C13.4667 22.72 13.2134 22.6266 13.0134 22.4266L7.29335 16.7066C7.10669 16.52 7.00002 16.2666 7.00002 16C7.00002 15.7333 7.10669 15.48 7.29335 15.2933L13.0134 9.57329C13.4 9.18663 14.04 9.18663 14.4267 9.57329C14.8134 9.95996 14.8134 10.6 14.4267 10.9866L10.4134 15H24C24.5467 15 25 15.4533 25 16C25 16.5466 24.5467 17 24 17Z" fill="#3F2021"/>
                </svg>
              </a>
              <h3 class="m-0 order-flow-title">Testing Club</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="dont-forgot d-flex gap-1">
                <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="mask0_3222_238" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1" y="3" width="14" height="14">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99998 3.33337C4.31998 3.33337 1.33331 6.32004 1.33331 10C1.33331 13.68 4.31998 16.6667 7.99998 16.6667C11.68 16.6667 14.6666 13.68 14.6666 10C14.6666 6.32004 11.68 3.33337 7.99998 3.33337ZM7.99998 10.6667C7.63331 10.6667 7.33331 10.3667 7.33331 10V7.33337C7.33331 6.96671 7.63331 6.66671 7.99998 6.66671C8.36665 6.66671 8.66665 6.96671 8.66665 7.33337V10C8.66665 10.3667 8.36665 10.6667 7.99998 10.6667ZM7.33331 12V13.3334H8.66665V12H7.33331Z" fill="black"/>
                  </mask>
                  <g mask="url(#mask0_3222_238)">
                  <rect y="2" width="16" height="16" fill="#2E5AAC"/>
                  </g>
                </svg>                            
                <p class="m-0">{{ customer.translations.do_not_forget_medal }}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h4 class="my-2">{{ customer.translations.like_your_logo_on_jersey }}</h4>
              <div class="form-group">
                <div v-if="customer.program_type === 'Monetary'">
                  <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <a href="javascript:;" class="coloring" @click="showImageViewOnly">{{ customer.translations.select_yes }}</a>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <a href="javascript:;" class="coloring" @click="monetaryCustomers">{{ customer.translations.select_no }}</a>
                    </div>
                    <!-- <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <a href="/customer_views" class="coloring">Back</a>
                    </div> -->
                  </div>
                </div>
                <div v-else>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <a href="javascript:;" 
                        class="coloring outlined-primary-btn d-flex gap-1 align-items w-100 justify-content-center py-14" 
                        @click="showPlaceYourOrderView">
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.5 1.5C5.3675 1.5 2 4.8675 2 9C2 13.1325 5.3675 16.5 9.5 16.5C13.6325 16.5 17 13.1325 17 9C17 4.8675 13.6325 1.5 9.5 1.5ZM12.02 10.725C12.2375 10.9425 12.2375 11.3025 12.02 11.52C11.9075 11.6325 11.765 11.685 11.6225 11.685C11.48 11.685 11.3375 11.6325 11.225 11.52L9.5 9.795L7.775 11.52C7.6625 11.6325 7.52 11.685 7.3775 11.685C7.235 11.685 7.0925 11.6325 6.98 11.52C6.7625 11.3025 6.7625 10.9425 6.98 10.725L8.705 9L6.98 7.275C6.7625 7.0575 6.7625 6.6975 6.98 6.48C7.1975 6.2625 7.5575 6.2625 7.775 6.48L9.5 8.205L11.225 6.48C11.4425 6.2625 11.8025 6.2625 12.02 6.48C12.2375 6.6975 12.2375 7.0575 12.02 7.275L10.295 9L12.02 10.725Z" fill="#C8102F"/>
                        </svg>
                        {{ customer.translations.select_no }}
                      </a>
                    </div>
                    <div class="col-12 col-md-6">
                      <a href="javascript:;" 
                        class="coloring primary-btn d-flex gap-1 align-items w-100 justify-content-center py-14" 
                        @click="showImageViewOnly">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 1.5C4.8675 1.5 1.5 4.8675 1.5 9C1.5 13.1325 4.8675 16.5 9 16.5C13.1325 16.5 16.5 13.1325 16.5 9C16.5 4.8675 13.1325 1.5 9 1.5ZM12.585 7.275L8.3325 11.5275C8.2275 11.6325 8.085 11.6925 7.935 11.6925C7.785 11.6925 7.6425 11.6325 7.5375 11.5275L5.415 9.405C5.1975 9.1875 5.1975 8.8275 5.415 8.61C5.6325 8.3925 5.9925 8.3925 6.21 8.61L7.935 10.335L11.79 6.48C12.0075 6.2625 12.3675 6.2625 12.585 6.48C12.8025 6.6975 12.8025 7.05 12.585 7.275Z" fill="white"/>
                        </svg>  
                        {{ customer.translations.select_yes }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="d-flex gap-1 align-items-center">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.6667 2.43329V9.35829C11.6667 10.2083 10.975 10.9 10.125 10.9H2.50002C2.04169 10.9 1.66669 10.525 1.66669 10.0666V4.74163C1.66669 3.04163 3.04169 1.66663 4.74169 1.66663H10.8917C11.325 1.66663 11.6667 2.00829 11.6667 2.43329Z" fill="#3F2021"/>
                  <path d="M17.9167 12.9166C18.15 12.9166 18.3334 13.1 18.3334 13.3333V14.1666C18.3334 15.55 17.2167 16.6666 15.8334 16.6666C15.8334 15.2916 14.7084 14.1666 13.3334 14.1666C11.9584 14.1666 10.8334 15.2916 10.8334 16.6666H9.16669C9.16669 15.2916 8.04169 14.1666 6.66669 14.1666C5.29169 14.1666 4.16669 15.2916 4.16669 16.6666C2.78335 16.6666 1.66669 15.55 1.66669 14.1666V12.5C1.66669 12.0416 2.04169 11.6666 2.50002 11.6666H10.4167C11.5667 11.6666 12.5 10.7333 12.5 9.58329V4.99996C12.5 4.54163 12.875 4.16663 13.3334 4.16663H14.0334C14.6334 4.16663 15.1834 4.49163 15.4834 5.00829L16.0167 5.94163C16.0917 6.07496 15.9917 6.24996 15.8334 6.24996C14.6834 6.24996 13.75 7.18329 13.75 8.33329V10.8333C13.75 11.9833 14.6834 12.9166 15.8334 12.9166H17.9167Z" fill="#3F2021"/>
                  <path d="M6.66667 18.3333C7.58714 18.3333 8.33333 17.5871 8.33333 16.6667C8.33333 15.7462 7.58714 15 6.66667 15C5.74619 15 5 15.7462 5 16.6667C5 17.5871 5.74619 18.3333 6.66667 18.3333Z" fill="#3F2021"/>
                  <path d="M13.3334 18.3333C14.2538 18.3333 15 17.5871 15 16.6667C15 15.7462 14.2538 15 13.3334 15C12.4129 15 11.6667 15.7462 11.6667 16.6667C11.6667 17.5871 12.4129 18.3333 13.3334 18.3333Z" fill="#3F2021"/>
                  <path d="M18.3333 10.4417V11.6667H15.8333C15.375 11.6667 15 11.2917 15 10.8333V8.33333C15 7.875 15.375 7.5 15.8333 7.5H16.9083L18.1167 9.61667C18.2583 9.86667 18.3333 10.15 18.3333 10.4417Z" fill="#3F2021"/>
                </svg>
                <h4 class="m-0 arrival-text">{{ customer.translations.allow_2_to_3_weeks_for_delivery_1 }} {{ customer.translations.allow_2_to_3_weeks_for_delivery_2 }}.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>

    <LogoUpload 
      v-if="showLogoUpload" 
      :currentUser="currentUser" 
      :customer="customer"
      :jerseys="jerseys"
      :variants="variants"
      :vendor="vendor"
      :rmm="rmm"
      :shorts="shorts"
      :shortsVariants="shortsVariants"
      :socks="socks"
      :socksVariants="socksVariants"
      :balls="balls"
      :ballsVariants="ballsVariants"
      :medals="medals"
      :medalsVariants="medalsVariants"
      :translations="translations"
    />

  </div>
  <PlaceYourOrder
    v-if="showPlaceYourOrder"
    :currentUser="currentUser"
    :customer="customer"
    :jerseys="jerseys"
    :variants="variants"
    :vendor="vendor"
    :rmm="rmm"
    :shorts="shorts"
    :shortsVariants="shortsVariants"
    :socks="socks"
    :socksVariants="socksVariants"
    :balls="balls"
    :ballsVariants="ballsVariants"
    :medals="medals"
    :medalsVariants="medalsVariants"
    :translations="translations"
  />
</template>

<script>
import LogoUpload from './LogoUpload.vue';
import PlaceYourOrder from './PlaceYourOrder.vue';

export default {
  name: 'OrderComponent',
  components: {
    LogoUpload,
    PlaceYourOrder
  },
  props: {
    currentUser: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    jerseys: {
      type: Object,
      required: true
    },
    variants: {
      type: Object,
      required: true
    },
    vendor: {
      type: Object,
      required: true
    },
    rmm: {
      type: Object,
      required: true
    },
    shorts: {
      type: Object,
      required: true
    },
    shortsVariants: {
      type: Object,
      required: true
    },
    socks: {
      type: Object,
      required: true
    },
    socksVariants: {
      type: Object,
      required: true
    },
    balls: {
      type: Object,
      required: true
    },
    ballsVariants: {
      type: Object,
      required: true
    },
    medals: {
      type: Object,
      required: true
    },
    medalsVariants: {
      type: Object,
      required: true
    },
    translations: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showLogoSelection: true,
      showLogoUpload: false,
      showPlaceYourOrder: false
    };
  },
  mounted() {
    console.log("Translations in logo selection:", this.translations)
  },
  methods: {
    showImageViewOnly() {
      this.showLogoSelection = false;
      this.showLogoUpload = true;
    },
    monetaryCustomers() {
      // Method logic here
    },
    showPlaceYourOrderView() {
      this.showLogoSelection = false;
      this.showPlaceYourOrder = true;
    }
  }
};
</script>
