<template>
  <div class="card active-users">
    <div class="card-header border-0">
      <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>

      <div class="row">
        <div class="col-lg-12 centered">
          <h4><strong>{{ $t("place_order") }}</strong></h4>
        </div>
      </div>

      <div class="row pt-4">
        <div class="col-lg-12 centered">
          <h5>{{ $t("association_name") }}: {{ customer.clubName }}</h5>
        </div>
      </div>

      <div class="row pt-2">
        <div class="col-lg-12 centered">
          <h5>{{ $t("age_group_for_jersey") }}</h5>
        </div>
      </div>

      <div class="row pt-4">
        <div class="col-xl-5 col-lg-4 col-md-4"></div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <div class="card">
            <div class="form-group">
              <select v-model="selectedAgeGroup" class="form-control" id="age_groups">
                <option :value="null" disabled>{{ $t("please_select") }}</option>
                <option v-for="ageGroup in ageGroups" :key="ageGroup" :value="ageGroup">{{ ageGroup }}</option>
              </select>
              <br />
            </div>
          </div>
        </div>
        <div class="col-xl-2 lg-1"></div>
      </div>

      <div class="row">
        <div class="col-xl-5 col-lg-4 col-md-4"></div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <div class="card">
            <div class="form-group">
              <button @click="continueOrder" class="btn btn-outline-primary btn-block" id="continueOrderBtn">
                {{ $t("continue_order") }}
              </button>
              <br />
            </div>
          </div>
        </div>
        <div class="col-xl-2 lg-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true
    },
    ageGroups: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedAgeGroup: null
    };
  },
  methods: {
    continueOrder() {
      // Emit selected age group to the parent component or handle the next step
      this.$emit("continue-order", this.selectedAgeGroup);
    }
  }
};
</script>
