<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-dialog" role="document">
      <div class="modal-content radius-8 p-4">
        <div class="text-center">
          <div class="d-flex gap-1 justify-content-center mb-1">
            <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M49.5 25C49.5 38.2548 38.7548 49 25.5 49C12.2452 49 1.5 38.2548 1.5 25C1.5 11.7452 12.2452 1 25.5 1C38.7548 1 49.5 11.7452 49.5 25Z" stroke="#C8102F" stroke-width="2"/>
              <path d="M15.5 24.5113L22.8067 31.818L35.6247 19" stroke="#C8102F" stroke-width="2"/>
            </svg>
            <div>
              <h3 class="modal-title mt-1" id="confirmModalLabel">{{ customer.translations.thank_you }}, {{ customer.name.split(' ')[0] }}!</h3>
            </div>
          </div>
          <p class="modal-text">{{ customer.translations.thank_you_message_2 }}.</p>
          <p style="color: #545D69; font-weight: 500; font-size: 16px;">Order Status : <span style="color: #C8102F;">{{ order.status }}</span></p>
          <p style="color: #545D69; font-weight: 500; font-size: 16px;">Order Number : <span style="color: #C8102F;">{{ order.generic_id }}</span></p>
          <div class="d-flex gap-1 justify-content-between max-280 mt-2">
            <button class="btn outlined-primary-btn px-1" @click="viewOrder">{{ customer.translations.view_order }}</button>
            <button class="btn primary-btn px-1" @click="continueShopping">{{ customer.translations.continue_shopping }}</button>
          </div>
          <button type="button" class="btn modal-secondary-btn no-btn close mt-2 w-100 text-center" @click="closeModal">{{ customer.translations.done }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
      window.location.reload();
    },

    viewOrder() {
      window.location.href = `/orders/${this.order.id}`;
    },

    continueShopping() {
      window.location.reload();
    }
  }
};
</script>
