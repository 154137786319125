<template>
  <div class="app-content content m-0">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-body">
        <Preloader v-if="showPreloader" />
        <PlaceOrder 
          :currentUser="currentUser"
          :customer="customer"
          :vendor="vendor"
          :rmm="rmm"
          :jerseys="jerseys"
          :variants="variants"
          :products="products"
          :shorts="shorts"
          :shortsVariants="shortsVariants"
          :socks="socks"
          :socksVariants="socksVariants"
          :balls="balls"
          :ballsVariants="ballsVariants"
          :medals="medals"
          :medalsVariants="medalsVariants"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from './Preloader.vue';
import PlaceOrder from './PlaceOrder.vue';

export default {
  components: {
    Preloader,
    PlaceOrder
  },
  data() {
    return {
      showPreloader: false,
      currentUser: {
        name: '',
      },
      customer: {
        club_name: '',
        translations: {
          welcome: '',
          from: '',
        }
      },
      jerseys: null,
      variants: null,
      vendor: null,
      rmm: null,
      shorts: null,
      shortsVariants: null,
      socks: null,
      socksVariants: null,
      balls: null,
      ballsVariants: null,
      medals: null,
      medalsVariants: null,
    };
  },
  mounted() {
    const data = document.getElementById('vue-app').dataset.currentUser;
    if (data) {
      const parsedData = JSON.parse(data);
      this.currentUser = parsedData.user;
      this.customer = parsedData.customer;
      this.jerseys = parsedData.jerseys;
      this.variants = parsedData.variants;
      this.vendor = parsedData.vendor;
      this.rmm = parsedData.rmm;
      this.shorts = parsedData.shorts;
      this.shortsVariants = parsedData.shorts_variants;
      this.socks = parsedData.socks;
      this.socksVariants = parsedData.socks_variants;
      this.balls = parsedData.balls;
      this.ballsVariants = parsedData.balls_variants;
      this.medals = parsedData.medals;
      this.medalsVariants = parsedData.medals_variants;
    }
  }
};
</script>
