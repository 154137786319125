<template>
  <div class="col-12">
    <div class="card p-5 radius-20 w-100 full-h-card">
      <div class="caerd-body">
        <div class="preloader js-preloader text-center">
          <img :src="imagePath" class="mar-top-350" :style="{width: '650px', marginTop: '150px'}" alt="Loading...">
          <!-- <h3 class="preloader-text">Please wait! Products are Loading...</h3> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  computed: {
    imagePath() {
      return require('../../assets/images/Timbits-Soccer-GIF-V2.1.gif');
    }
  }
};
</script>